import Vue from 'vue'
import App from './App.vue'
//引入路由组件
import router from './router'
//引入element ui 组件库
import './plugins/element.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//引入粒子插件
import VueParticles from 'vue-particles'
//引入全局样式表
import './assets/css/global.css'
//引入图书管理系统的图标和字体库
import './assets/fonts/iconfont.css'

// 引入swiper的样式
// import 'swiper/css/swiper.css'
//单独引入Message axios拦截器需要
import {Message} from 'element-ui'
// 引入表格导出excel插件
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// 导入pdf插件
import htmlToPdf from './components/Utils/htmlToPdf.js'
Vue.use(htmlToPdf)
// 引入echarts
import * as echarts from 'echarts'
// 引入Chrome PassiveEventListeners优化页面性能
import 'default-passive-events'
// 引入lodash
import _ from 'lodash'
Vue.prototype._ = _  //全局导入的挂载方式
// 设置全局变量
Vue.prototype.$echarts = echarts

//注册粒子插件
Vue.use(VueParticles)
//main.js:
import { vueBaberrage } from 'vue-baberrage'
//使用弹幕插件
Vue.use(vueBaberrage)
//导入axios
import axios from 'axios'
//配置请求的根路径
axios.defaults.baseURL = 'https://book.lilihouse.cn/api'
Vue.prototype.$http = axios
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
// export {source}
//导入NProgress包对应的js和CSS
import  NProgress  from 'nprogress'
import 'nprogress/nprogress.css'
//在request拦截器中，展示进度条NProgress
axios.interceptors.request.use(config => {
  NProgress.start(); // 开始显示加载进度条
  config.headers.Authorization = "Bearer " + window.sessionStorage.getItem('token'); // 设置请求头中的 Authorization 字段
  return config; // 返回配置好的请求
});
axios.interceptors.response.use(
    response => {
      NProgress.done(); // 结束加载进度条
      if (response.data.status === 401) { // 如果服务器返回的状态码为 401
        Message.error("未登录或登录过期，请重新登录"); // 显示错误消息
        window.sessionStorage.clear(); // 清除 session storage 中的数据
        router.replace('/home'); // 导航到登录页面
      }
      // else if (response.data.status === 404){
      //   Message.error('访问的资源或地址不存在');
      //   router.replace('/404');
      // }
      return response; // 返回响应
    },
    error => { // 处理错误响应
      NProgress.done(); // 结束加载进度条
      if (error.response && error.response.data.status === 401) { // 如果是特定的错误状态码
        Message.error("未登录或登录过期，请重新登录"); // 显示错误消息
        window.sessionStorage.clear(); // 清除 session storage 中的数据
        router.replace('/home'); // 导航到登录页面
      }
      return Promise.reject(error); // 返回拒绝的 promise
    }
);

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
